<template>
<v-card class="ma-2" elevation="0" style="background-color:white;">
  <v-container elevation="0" style="background-color:white;">
    <v-row class="mt-1">
      <v-text-field type="text" dense v-model="search" :autofocus="true" placeholder="搜索" outlined append-icon="mdi-magnify" @click="stopParentEvent" />
    </v-row>
    <v-row>
      <v-divider class="mb-3"></v-divider>
      <!-- <div style="min-height:300px;" class="pa-1"> -->
      <v2-lazy-list :data="filteredList" :height="360" :item-height="40" tag="div" item-tag="div">
        <template slot-scope="item">
          <v-btn width="290" @click="select(item)" :key="item.id" class="d-flex elevation-0 justify-start align-center ma-1" color="#BBDEFB">
            <v-avatar size="30" class="mx-2" color="#E65100">{{ item.employeeName.charAt(0).toUpperCase() }}</v-avatar>
            <span class="text-capitalize">{{ item.employeeName }} ({{ item.loginName }})</span>
          </v-btn>
        </template>
      </v2-lazy-list>
      <!-- </div> -->
    </v-row>
  </v-container>
</v-card>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
import Vue from 'vue';

export default {
  name: 'UserPicker',
  data() {
    return {
      search: "",
      // userList: [],
      scrolled: false
    };
  },
  computed: {
    ...mapGetters("user", ["users"]),
    filteredList() {
      if (!!this.search) {
        this.userList = this.users
      }
      return this.users.filter(user => {
        if (user.employeeName?.toLowerCase().includes(this.search) ||
          user.loginName?.toLowerCase().includes(this.search) && user.status == 1) {
          return user
        }
      });
    },
  },
  methods: {
    ...mapActions("user", ["getUsersCallBack", 'getUsers']),
    select(user) {
      this.$emit('pick', {
        user: user
      })
    },
    stopParentEvent(ev) {
      ev.stopPropagation();
    },
    // onScroll() {
    //   if (!this.scrolled) {
    //     this.scrolled = true
    //   } else {
    //     if (this.userList.length < this.users.length) {
    //       this.userList = this.users
    //     }
    //   }
    // }
  },
  mounted() {
    // this.getUsersCallBack().then(() => {
    //   for (let i = 0; i < 10; i++) {
    //     this.userList.push(this.users[i])
    //   }
    // })
    // this.$nextTick(function () {
    //   document.addEventListener('scroll', this.onScroll);
    //   this.onScroll(); // needed for initial loading on page
    // });
    this.getUsers();
  },
  beforeDestroy() {

  }
}
</script>
