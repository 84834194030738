<template>
<v-container>
  <v-tabs v-model="tab" fixed-tabs hide-slider>
    <v-tab>
      <div>
        <v-icon class="tab-icon pr-1">
          mdi-message-processing-outline
        </v-icon>
        <span>
          互动
        </span>
      </div>
    </v-tab>
    <v-tab>
      <div>
        <v-icon class="tab-icon pr-1">
          mdi-checkbox-marked-outline
        </v-icon>
        <span>
          子任务
        </span>
      </div>
    </v-tab>
    <v-tab>
      <div>
        <v-icon class="tab-icon pr-1">
          mdi-notebook-edit-outline
        </v-icon>
        <span>
          进展
        </span>
      </div>
    </v-tab>
    <v-tab>
      <div>
        <v-icon class="tab-icon pr-1">
          mdi-camera-control
        </v-icon>
        <span>
          审批
        </span>
      </div>
    </v-tab>
    <v-tab>
      <div>
        <v-icon class="tab-icon pr-1">
          mdi-timer
        </v-icon>
        <span>
          工时
        </span>
      </div>
    </v-tab>
  </v-tabs>
  <v-tabs-items v-model="tab">
    <v-tab-item>
      <Interactive :task_id="content.task_id" />
    </v-tab-item>
    <v-tab-item>
      <SubTask :task_id="content.task_id" :sub_tasks="content.task_tasks" @openEdit="openEdit" />
    </v-tab-item>
    <v-tab-item>
      <Progress :task_id="content.task_id" />
    </v-tab-item>
    <v-tab-item>
      <Approve />
    </v-tab-item>
    <v-tab-item>
      <WorkingHour :task_id="content.task_id" />
    </v-tab-item>
  </v-tabs-items>

</v-container>
</template>

<script>
import Tabs from '@/components/common/taskEdit/taskBottomEdit/tabs/Tabs.vue';
import Interactive from '@/components/common/taskEdit/taskBottomEdit/interactive/Interactive.vue';
import Progress from '@/components/common/taskEdit/taskBottomEdit/progress/Progress.vue';
import SubTask from '@/components/common/taskEdit/taskBottomEdit/subtask/Subtask.vue';
import Approve from '@/components/common/taskEdit/taskBottomEdit/approve/Approve.vue';
import WorkingHour from '@/components/common/taskEdit/taskBottomEdit/workingHour/WorkingHour.vue';

export default {
  props: ['content'],
  data() {
    return {
      tab: null,
    }
  },
  components: {
    Tabs,
    Interactive,
    Progress,
    SubTask,
    Approve,
    WorkingHour,
  },
  methods: {
    // Handle SubTaskEditor Window Display
		openEdit(task) {
      this.$emit('openEdit', task)
    },
  }
}
</script>

<style scoped>
.tab-icon {
  font-size: 17px;
}

.v-tab--active div {
  background-color: rgb(56, 186, 115) !important;
  width: 100px;
  border-radius: 30px !important;
  padding: 1px 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: all ease 1s;
  color: #fff;
}
</style>
