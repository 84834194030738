<template>
<v-flex class="w100">
  <v-sheet>
    <v-container align-center justify-start d-flex>
      <v-layout align-center justify-start column>
        <!-- <Operating :task_id="task_id" /> -->
        <Operating v-if="!!task_id" :op_obj="this.op_obj" />
        <Intercom v-if="!!task_id" :ic_obj="this.ic_obj" />
      </v-layout>
    </v-container>
  </v-sheet>
</v-flex>
</template>

<script>
// import Operating from '@/components/common/taskEdit/taskBottomEdit/interactive/operating/Operating.vue';
import Operating from '@/components/common/operating/Operating.vue';
// import Intercom from '@/components/common/taskEdit/taskBottomEdit/interactive/intercom/Intercom.vue';
import Intercom from "@/components/common/Intercom/Intercom.vue";

export default {
  props: ['task_id'],
  data() {
    return {
      ic_obj: {
        ic_parent: this.task_id,
        ic_parent_type: 2,
      },
      op_obj: {
        op_parent: this.task_id,
        op_parent_type: 2,
      },
    }
  },
  components: {
    Operating,
    Intercom,
  },
  mounted() {
    this.ic_obj = {
      ic_parent: this.task_id,
      ic_parent_type: 2,
    };
    this.op_obj = {
      op_parent: this.task_id,
      op_parent_type: 2,
    };
  },
  watch: {
    task_id(value, oldValue) {
      this.ic_obj = {
        ic_parent: value,
        ic_parent_type: 2,
      };
      this.op_obj = {
        op_parent: value,
        op_parent_type: 2,
      };
    },
  },
}
</script>

<style>
</style>
