<template>
<v-flex class="limitOperators">
  <v-flex @click="clickDropDown" class="pa-1">
    <span class="listItems" style="cursor: pointer;">全部操作 {{ operators.length }}</span>
    <v-icon class="icon_down" :class="dropDownChk ? 'icon_down_rotate' : ''">
      mdi-chevron-up
    </v-icon>
  </v-flex>
  <!-- <v-sheet class="loading-spinner" v-if="loading">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-sheet> -->
  <v-sheet v-if="operators.length==0" style="height: 60px;display: flex;align-items: center;justify-content: center;border: 1px dashed rgb(196 202 210);margin-top:10px;">
    <span style="color:#9aa6b2;">经营不存在</span>
  </v-sheet>

  <div style="max-width:700px; padding-right: -160px; position: relative;">
    <v-timeline clipped v-if="operators.length>0">
      <OperatingItem v-for="(op, index) in (dropDownChk ? operators:operators.slice(0,3))" :op_obj="op" :color="index == 0 ? 'green lighten-1' : 'cyan lighten-1'" />
    </v-timeline>
  </div>
</v-flex>
</template>

<script>
// import OperatingItem from './OperatingItem.vue';
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  props: ['op_obj','mode'],
  components: {
    OperatingItem: () => import('@/components/common/operating/OperatingItem.vue'),
  },
  data() {
    return {
      dropDownChk: false,
      loading: false,
			operators:[],
    }
  },
  watch: {
    op_obj(v) {
      if (!!v.op_parent) {
        this.getOperators(v);
      }
    }
  },
  created() {
    
    if (!!this.op_obj.op_parent) {
      this.getOperators(this.op_obj).then((res) => {
				this.operators = res;
				if(this.mode=="value"){
					this.operators = this.operators.filter(e=>{
						return e.op_description.includes("值为")
					})
				}
        // if (res == "success") {
        //   this.loading = false;
        // }
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    // ...mapGetters('operating', ['operators']),
  },
  methods: {
    ...mapActions('operating', ['getOperators']),
    clickDropDown() {
      this.dropDownChk = !this.dropDownChk;
    }
  },
}
</script>

<style lang="scss">
$timeline-item-padding: 12px;
$timeline-line-width:10px;
$timeline-inner-dot-small-size:4px;
</style><style scoped>
.v-timeline-item {
  display: flex;
  padding: 4px !important;
}

.v-timeline {
  padding: 2px !important;
}

.v-timeline-item__inner-dot {
    margin: 0px !important;
    height: 8px !important;
    width: 8px !important;
}

.v-timeline-item__dot {
    height: 12px !important;
    width: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style><style>
.limitOperators {
  width: 100%;
  /* margin-bottom: 20px; */
}

.listItems {
  font-size: 12px;
  color: grey;
}

.limitOperators:hover .listItems {
  color: blue ;
}

.limitOperators:hover .icon_down {
  color: blue !important;
}

.icon_down {
  font-size: 13px !important;
  transition: all .5s ease;
}

.icon_down_rotate {
  transform: rotate(180deg);
}

.listTime_day {
  color: rgb(153, 163, 173);
  font-size: 12px;
  margin-right: 25px;
}

.listTime_time {
  color: rgb(58, 66, 71);
  font-size: 12px;
}

.listTime {
  font-size: 14px;
}

.card_text_pre {
  padding: 3px !important;
}

.loading-spinner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty_operating {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgb(196 202 210);
}
</style>
