<template>
<v-container >
    <TaskEditHeader v-if="!!taskInfo" mode="page" :content="taskInfo" @closeTaskEditor="handle_closeTaskEditor" @selectPriority="handle_selectPriority" />
    <TaskContentEdit v-if="!!taskInfo" mode="page" :content="taskInfo" @updateTaskOne="handle_updateTaskOne" />
    <BottomEdit v-if="!!taskInfo" :content="taskInfo" @showSubTaskEditor="handle_showSubTaskEditor" />
</v-container>
</template>

<script>
import {
  mapGetters,
  mapActions
} from "vuex";
import TaskEditHeader from '@/components/common/taskEdit/header/TaskEditHeader.vue';
import TaskContentEdit from '@/components/common/taskEdit/taskContentEdit/ContentEdit.vue';
import BottomEdit from '@/components/common/taskEdit/taskBottomEdit/BottomEdit.vue';

export default {
  name: 'TaskDetail',
  components: {
    TaskEditHeader,
    TaskContentEdit,
    BottomEdit,
  },
  data() {
    return {
      show: false,
      taskID: null,
      taskInfo: null,
    }
  },
  created() {
    const task_id = this.$route.query.id;
    this.getTaskById({
      "task_id": task_id
    }).then((res) => {
      if (res == "success") {
        this.taskInfo = this.task;

      }
    });    
    this.show = true;
  },
  computed: {
    ...mapGetters('task', ['task']),
  },
  methods: {
    ...mapActions('task', ['getTaskById']),
    ...mapActions('task', ['updateTaskOne']),
    async openTaskEditor(prop) {
      const res = await this.getTaskById({
        "task_id": $route.query.id
      });
      if (res == "success") {
        this.taskInfo = this.task;
      }
      this.show = true;
    },
    handle_closeTaskEditor() {
      this.show = false;
    },
    handle_selectPriority(prop) {
      this.priorityValue = prop;
    },
    async handle_showSubTaskEditor(prop) {
      this.show = true;
      const res = await this.getTaskById({
        "task_id": prop
      });
      if (res == "success") {
        this.taskInfo = this.task;
      }
    },
    async handle_updateTaskOne(prop) {
      const res = await this.updateTaskOne(prop);
      if (res == "success") {
        const res = await this.getTaskById({
          "task_id": $route.query.id
        });
        if (res == "success") {
          this.taskInfo = this.task;
        }
      }
    }
  },
}
</script>
