<template>
<v-container class="mt-4">
  <v-row class="pt-2">
    <v-flex grow>
      <v-icon style="color:#106ecc;font-size:16px;margin-right:5px;">mdi-bullseye-arrow</v-icon><span class="" style="color:#106ecc;font-size:13px;">量化</span>
    </v-flex>
    <ConfirmDialogComponent v-on:click="(bool) => confirmClick(bool)" :option="task">
      <v-flex shrink>
        <v-icon class="cursorPointer pr-5" style="color:#bfc3c7;font-size:18px;">mdi-delete-outline</v-icon>
      </v-flex>
    </ConfirmDialogComponent>
  </v-row>
  <v-row class="pa-2  align-center">
    <div class="pr-7">
      <span class="quantify_title">目标值</span>
      <span v-if="!editTarget" class="quantify_value">{{ targetPrice }}</span>
      <v-text-field v-if="editTarget" class="edit_target_price" hide-details v-model="targetPrice" 
			:rules = "[v => (/^(\d|\.)*$/.test(v) ) || '请输入数字']"
			v-on:keyup.enter="submitChangedTargetPrice"></v-text-field>
      <ToolTipComponent direction="top" tooltipText="编辑">
        <span class="cursorPointer" @click="editTargetPrice">
          <v-icon class="quanity_edit_pencil">mdi-pencil</v-icon>
        </span>
      </ToolTipComponent>
      <span class="unit" @click="displayUnitModal"><span class="unit_value">({{unit[taskUnit-1].value}})</span>
        <v-icon class="down_icon">mdi-menu-down</v-icon>
      </span>
      <UnitModal ref="unit" @unitSubmit="handle_unitSubmit" />
    </div>
    <div class="pr-10">
      <span class="quantify_title">实际值</span>
      <span v-if="!editReal" class="quantify_value">{{ realPrice }}</span>
      <v-text-field v-if="editReal" class="edit_target_price" hide-details v-model="realPrice"
			:rules = "[v => (/^(\d|\.)*$/.test(v) ) || '请输入数字']"
			 v-on:keyup.enter="submitChangedRealPrice"></v-text-field>
      <ToolTipComponent direction="top" tooltipText="编辑">
        <span class="cursorPointer mr_5" @click="editRealPrice">
          <v-icon class="quanity_edit_pencil">mdi-pencil</v-icon>
        </span>
      </ToolTipComponent>
      <span class="unit" @click="displayUnitModal"><span class="unit_value">({{unit[taskUnit-1].value}})</span>
        <v-icon class="down_icon">mdi-menu-down</v-icon>
      </span>
      <UnitModal ref="unit" @unitSubmit="handle_unitSubmit" />
    </div>
    <div @click="showTaskOperators">
      <span class="collapse_title">展开记录</span>
      <v-icon class="collapse_icon" :class="task_operators ? 'collapse_icon_rotate' : ''">mdi-chevron-down</v-icon>
    </div>
  </v-row>
  <v-row v-if="task_operators" class="pt-2">
    <Operating mode="value" :op_obj="{
        op_parent: taskID,
        op_parent_type: 2,
    }"/>
  </v-row>
</v-container>
</template>

<script>
import UnitModal from '@/components/common/taskEdit/taskContentEdit/UnitModal.vue';
// import Operating from '@/components/okrgoal/rightInfo/interactive/operating/Operating.vue';
import ToolTipComponent from '@/components/okrgoal/shared/ToolTipComponent.vue';
import ConfirmDialogComponent from '@/components/okrgoal/shared/ConfirmDialogComponent.vue';
import {
  mapActions
} from 'vuex';

export default {
  props: ['taskID', 'task_parent_object', 'target_price', 'real_price', 'unitID'],
  components: {
    UnitModal,
    Operating:() => import('@/components/common/operating/Operating.vue'),
    ToolTipComponent,
    ConfirmDialogComponent,
  },
  data() {
    return {
      editTarget: false,
      editReal: false,
      changedTargetPrice: null,
      changedRealPrice: 0,
      unit: [{
        value: "元"
      }, {
        value: "个"
      }],
      unit_id: 1,
      task_operators: false,
      task: "task",
			targetPrice:0,
			realPrice:0,
    }
  },
  created() {
    this.changedRealPrice = 0;
		this.targetPrice = this.target_price;
		this.realPrice = this.real_price;
  },
  computed: {
    // targetPrice: {
    //   get: function () {
    //     if (!!this.changedTargetPrice) {
    //       return this.changedTargetPrice;
    //     } else if (!!this.target_price) {
    //       return this.target_price;
    //     }else {
    //       return 0;
    //     }
    //   },
    //   set: function (newValue) {
    //     this.changedTargetPrice = newValue;
    //   }
    // },
    // realPrice: {
    //   get: function () {
    //     if (!!this.changedRealPrice) {
    //       return this.changedRealPrice;
    //     } else if (!!this.real_price) {
    //       return this.real_price;
    //     } else {
    //       return 0;
    //     }
    //   },
    //   set: function (newValue) {
    //     this.changedRealPrice = newValue;
    //   }
    // },
    taskUnit() {
      if (!!this.unit_id) {
        return this.unit_id;
      } else if (!!this.unitID) {
        return this.unitID;
      }
    },
  },
  methods: {
    ...mapActions('task', ['updateTaskOne']),
    editTargetPrice() {
      this.editTarget = true;
    },
    editRealPrice() {
      this.editReal = true;
    },
    submitChangedTargetPrice() {
			if(this.targetPrice.match(/^(\d|\.)+$/) ){
				this.editTarget = false;
				if (!!this.targetPrice) {
					this.$emit("editTargetPrice", this.targetPrice);
				}
			}
    },
    submitChangedRealPrice() {
			if(this.realPrice.match(/^(\d|\.)+$/) ){
				this.editReal = false;
				if (!!this.realPrice) {
					this.$emit("editRealPrice", this.realPrice);
				}
			}
    },
    displayUnitModal() {
      this.$refs.unit.open();
    },
    handle_unitSubmit(prop) {
      this.unit_id = prop;
      this.$emit("editTaskUnit", this.unit_id);
    },
    showTaskOperators() {
      this.task_operators = !this.task_operators;
    },
    confirmClick(prop) {
      if (prop == true) {
        this.updateTaskOne({
          'task_id': this.taskID,
          'task_parent_object': this.task_parent_object,
          'task_target_price': 0
        });
        this.changedRealPrice = 0;
      }
    }
  }
}
</script>

<style>
.quantifyEdit {
  background: #fafbfc !important;
  border-radius: 6px;
}

.height_100 {
  height: 100%;
}

.height_24 {
  height: 24px;
}

.d_flex_quantify {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.m_20_0 {
  margin: 20px 0px 0px 0px;
}

.mt_10 {
  margin-top: 10px;
}

.mr_5 {
  margin-right: 5px;
}

.p_5 {
  padding: 5px;
}

.p_5_10 {
  padding: 5px 10px;
}

.ptb_5 {
  padding: 5px 0px;
}

.pt_10 {
  padding-top: 10px;
}

.font_family {
  font-family: "Helvetica Neue", Helvetica, arial, "Hiragino Sans GB", \5b8b\4f53, sans-serif;
}

.horizontal_divider {
  border: 1px dashed #d9e2e4;
}

.quantify_title {
  font-size: 16px;
  color: #83898f;
  margin-right: 15px;
}

.quantify_value {
  font-size: 18px;
  color: #55c5c6;
  margin-right: 10px;
}

.quanity_edit_pencil {
  font-size: 12px !important;
  padding: 2px;
  border-radius: 4px;
}

.editTarget:hover .quanity_edit_pencil {
  background: rgb(213 233 234);
  opacity: 1;
}

.edit_target_price {
  width: 70px;
  margin-right: 10px !important;
  margin: 0px;
  padding: 0px;
}

.unit {
  padding: 0 4px;
  cursor: pointer;
}

.unit_value {
  color: #bfc3c7;
  font-size: 14px !important;
}

.down_icon {
  color: #bfc3c7 !important;
  font-size: 18px !important;
}

.quantifyCollapse {
  cursor: pointer;
}

.collapse_title {
  font-size: 14px;
  color: #83898f;
  margin: 0px;
}

.collapse_icon {
  transition: all ease .7s;
  font-size: 16px !important;
  color: #83898f !important;
}

.collapse_icon_rotate {
  transform: rotate(180deg);
}

.quantifyCollapse:hover .collapse_title {
  color: #318ce7;
}

.quantifyCollapse:hover .collapse_icon {
  color: #318ce7 !important;
}
</style>
