<template>
<v-navigation-drawer v-model="show" right temporary  fixed width="720" overlay-color="white" overlay-opacity="0" color="rgb(250, 251, 252)" class="task-editor1">
  <TaskEditHeader v-if="!!taskInfo" :content="taskInfo" @closeTaskEditor="handle_closeTaskEditor" @selectPriority="handle_selectPriority" />
  <TaskContentEdit v-if="!!taskInfo" :content="this.task" @updateTaskOne="handle_updateTaskOne" />
  <BottomEdit v-if="!!taskInfo" :content="taskInfo" @openEdit="openEdit" />
</v-navigation-drawer>
</template>

<script>
import TaskEditHeader from '@/components/common/taskEdit/header/TaskEditHeader.vue';
import TaskContentEdit from '@/components/common/taskEdit/taskContentEdit/ContentEdit.vue';
import BottomEdit from '@/components/common/taskEdit/taskBottomEdit/BottomEdit.vue';
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  components: {
    TaskEditHeader,
    TaskContentEdit,
    BottomEdit,
  },
  data() {
    return {
      show: false,
      taskID: null,
      taskInfo: null,
    }
  },
  computed: {
    ...mapGetters('task', ['task']),
  },
  methods: {
    ...mapActions('task', ['getTaskById']),
    ...mapActions('task', ['updateTaskOne']),
    async openTaskEditor(prop) {
      this.show = true;
      const res = await this.getTaskById({
        "task_id": prop.task_id
      });
      if (res == "success") {
        this.taskInfo = this.task;
      }
    },

    handle_closeTaskEditor() {
      this.show = false;
    },
    handle_selectPriority(prop) {
      this.priorityValue = prop;
    },
    openEdit(task) {
      this.show = false;
      this.getTaskById({
        "task_id": task.task_id
      }).then((res) => {
        if (res == "success") {
          this.taskInfo = this.task;
          this.show = true;
        }
      });
    },
    async handle_updateTaskOne(prop) {
      const res = await this.updateTaskOne(prop);
      if (res == "success") {
        const res = await this.getTaskById({
          "task_id": prop.task_id
        });
        if (res == "success") {
          this.taskInfo = this.task;
        }
      }
    }
  }
}
</script>

<style>
.task-editor {
  height: calc(100% - 40px) !important;
  z-index: 12;
  position: fixed;
  top: 40px !important;
  right: 0px;
}
</style>
