<template>
<v-flex class="justify-start" style="max-width:600px;">
  <v-row class="align-center">
    <span class="relatedMember_title">相关成员</span>
  </v-row>
  <v-row class="pa-2">
    <v-col>
      <v-row>
        <span class="task_title">发起者</span>
      </v-row>
      <v-row>
        <v-avatar color="#96bf65" size="33" class="mr_20 cursorPointer">{{ relatedTaskCreator | surename }}</v-avatar>
        <span>{{ relatedTaskCreator | username }}</span>
      </v-row>
    </v-col>
    <v-col>
      <v-row>
        <span class="task_title">负责人</span>
      </v-row>
      <v-row>
        <ToolTipComponent direction="top" :tooltipText="relatedTaskOwner | username">
          <v-avatar color="#f79c6f" size="33" class="mr_20 cursorPointer">{{ relatedTaskOwner | surename }}</v-avatar>
        </ToolTipComponent>
        <span class="mr_15">{{ relatedTaskOwner | username }}</span>
        <v-menu width="300" offset-y class="attributes_major">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="cursorPointer related_major_pencil">
              <v-icon style="color:#83898F; font-size:14px !important; margin-right:5px;">mdi-pencil</v-icon>
            </span>
          </template>
          <v-sheet>
            <UserPicker @pick="setOwner" />
          </v-sheet>
        </v-menu>
      </v-row>
    </v-col>
  </v-row>
  <v-row class="pa-2">
    <v-col>
      <v-row>
        <span class="task_title">审批人</span>
      </v-row>
      <v-row v-if="!!approver && approver !== -1">
        <v-avatar color="#79c7b7" size="33" class="mr-1 cursorPointer">{{ relatedTaskApprover | surename }}</v-avatar>
        <v-icon class="task_relates_approve_remove" @click="removeApprover">mdi-close-circle</v-icon>
        <span class="mr-2">{{ relatedTaskApprover | username }}</span>
        <v-menu v-if="approverExist" width="300" offset-y class="task_approver">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="cursorPointer related_approver_pencil">
              <v-icon style="color:#83898F; font-size:18px !important; margin-right:5px;">mdi-pencil</v-icon>
            </span>
          </template>
          <v-sheet>
            <UserPicker @pick="setApprover" />
          </v-sheet>
        </v-menu>
      </v-row>
      <v-row v-else>
        <v-menu v-if="!approverExist" width="300" offset-y class="task_approver">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="plus">mdi-plus-circle-outline</v-icon>
          </template>
          <v-sheet>
            <UserPicker @pick="setApprover" />
          </v-sheet>
        </v-menu>
      </v-row>
    </v-col>
    <v-col>
      <v-row>
        <span class="task_title">参与人</span>
      </v-row>
      <v-row>
        <div v-if="!!collaborators" v-for="(collaborator, index) in relatedTaskCollaborators" :key="index">
          <div class="">
            <ToolTipComponent direction="top" :tooltipText="collaborator | username">
              <v-avatar color="#f5b168" size="33" class="cursorPointer">{{ collaborator | surename }}</v-avatar>
              <span @click="removeTaskCollaborator(collaborator)">
                <v-icon class="remove_task_collaborator">mdi-close-circle</v-icon>
              </span>
            </ToolTipComponent>
          </div>
          <div class=""><span style="font-size: 14px;">{{ collaborator | username }}</span></div>
        </div>
        <div>
          <v-flex class="">
            <v-menu width="300" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="plus">mdi-plus-circle-outline</v-icon>
              </template>
              <v-sheet>
                <UserPicker @pick="setCollaborator" />
              </v-sheet>
            </v-menu>
          </v-flex>
        </div>
      </v-row>
    </v-col>
  </v-row>
</v-flex>
</template>

<script>
import ToolTipComponent from '@/components/okrgoal/shared/ToolTipComponent.vue';
import UserPicker from '@/components/common/UserPicker.vue';

export default {
  props: ['creator', 'owner', 'approver', 'collaborators'],
  components: {
    ToolTipComponent,
    UserPicker,
  },
  data() {
    return {
      approverExist: false,
    }
  },
  created() {
    if (!!this.approver && this.approver != -1) {
      this.approverExist = true;
    } else {
      this.approverExist = false;
    }
  },
  watch: {
    approver(v) {
      if (v != -1) {
        this.approverExist = true;
      } else {
        this.approverExist = false;
      }
    }
  },
  computed: {
    relatedTaskCreator() {
      if (!!this.creator) {
        return this.creator;
      }
    },
    relatedTaskOwner() {
      if (!!this.owner) {
        return this.owner;
      }
    },
    relatedTaskApprover() {
      if (!!this.approver && this.approver != 0) {
        return this.approver;
      }
    },
    relatedTaskCollaborators() {
      if (!!this.collaborators) {
        return this.collaborators;
      }
    }
  },
  methods: {
    setOwner(prop) {
      this.$emit("setOwner", prop.user.id);
    },
    setApprover(prop) {
      this.approverExist = true;
      this.$emit("setApprover", prop.user.id);
    },
    removeApprover() {
      this.approverExist = false;
      this.$emit("removeApprover");
    },
    setCollaborator(prop) {
      this.$emit("setCollaborator", prop.user.id);
    },
    removeTaskCollaborator(prop) {
      this.$emit("removeCollaborator", prop);
    }
  }
}
</script>

<style>
.relatedMember_title {
  position: relative;
  left: -20px;
  width: 175px;
  height: 26px;
  font-size: 14px;
  color: #565e66;
  background: #fbf2df;
  border-radius: 0 15px 15px 0;
  text-align: center;
}

.plus {
  font-size: 33px !important;
  color: #667580 !important;
  background-color: #e4ebf0;
  border-radius: 50%;
  cursor: pointer;
}

.related_major_pencil {
  position: relative;
  bottom: 2px;
  transition: all ease .5s;
}

.related_approver_pencil {
  position: relative;
  bottom: 2px;
  transition: all ease .5s;
}

.attributes_major:hover .related_major_pencil {
  opacity: 1;
}

.task_approver:hover .related_approver_pencil {
  opacity: 1;
}

.task_title {
  color: #83898f;
  font-size: 14px;
}

.task_relates_approve_remove {
  position: relative !important;
  top: -10px;
  left: -10px;
  color: #bfc3c7;
  font-size: 14px !important;
  border-radius: 50%;
  cursor: pointer;
}

.task_relates_approve_remove:hover {
  color: #f04d4d;
}

.task_approver:hover .task_relates_approve_remove {
  opacity: 1;
}

.remove_task_collaborator {
  transition: all ease .5s !important;
  font-size: 13px !important;
  position: relative !important;
  right: 10px !important;
  bottom: 13px !important;
  color: #76adc8 !important;
  cursor: pointer;
}

.remove_task_collaborator:hover {
  color: #f04d4d !important;
}
</style>
